<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <!-- <vuexy-logo /> -->

          <!-- <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2> -->
        </b-link>
        <div class="forgotPasswordContainer" v-if="!isPasswordSent">
          <b-card-title class="mb-1">
            Forgot Password?
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules" #default="{invalid}">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-alert
                v-if="errorMsg"
                variant="danger"
                show
              >
                <div class="alert-body">
                  {{ errorMsg }}
                </div>
              </b-alert>
              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Send Email
              </b-button>
            </b-form>
          </validation-observer>
        </div>
        <div class="passwordSentContainer" v-else>
          <b-card-title class="mb-1">
            Email Sent to {{userEmail}}
          </b-card-title>
          <b-card-text class="mb-2">
            Did not receive it? Please send the email again
          </b-card-text>
          <b-button
            variant="primary"
            block
            type="submit"
            @click="sendEmail"
          >
            Re-Send Email
          </b-button>
        </div>
        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    // VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      errorMsg: '',
      isPasswordSent: false,
    }
  },
  methods: {
    sendEmail() {
      this.$store.dispatch('users/forgotPassword', { username: this.userEmail }).then(response => {
        if (response.code === 200) {
          this.isPasswordSent = true
        } else {
          this.errorMsg = response.data.meessage
        }
      }).catch(err => {
        console.error(err)
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.sendEmail()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
